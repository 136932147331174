import React, { useContext, useState } from "react";
import {
  Div,
  H6,
  Text,
  Divider,
  Avatar,
  Icons,
  Container,
  Row,
  SideMenu,
  Col,
} from "qdm-component-library";
import Drawer from '@mui/material/Drawer';

import { UIColor } from "../../../themes/theme";
import { CustomDrawer } from "../drawer";
import { Hamburger } from "../../svg";
import Logo from "../../../assets/img/svg/Logo_W.svg";
import ForsightLogo from "../../../assets/img/png/ForsightLogo.jpeg";

import {
  getUserInfo,
  Logout,
  CapitalizeString,
  getImgUrl,
  AlertProps,
  isFullScreen,
  checkError,
  __tenantid__,
  urls,
} from "../../../utils";
import {
  Typography,
  Popover,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@material-ui/core";
import {
  getDrawerData,
  giveMeSelectedTab,
  clickHome,
  changeState,
  handleProfileClick,
  handleProfileClose,
} from "./handlers";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import SwapHorizontalCircleIcon from "@material-ui/icons/SwapHorizontalCircle";
// import SupervisedUserCircleSharpIcon from "@material-ui/icons/SupervisedUserCircleSharp";
import LocationRoles from "../../locationAndRoles/locationRoles";
import { AppRoutes } from "../../../router/routes";
import { AlertContext } from "../../../context";
import axios from "axios";
import CloseIcon from "@material-ui/icons/Close";
import { useLocation } from "react-router-dom";
// import { useDispatch } from "react-redux";

const AppHeader = (props) => {
  const userInfo = localStorage.getItem("UserInfo");
  const location = useLocation();
  const decodedUserinfo = jwtDecode(userInfo);
  const roleName = decodedUserinfo.rolename;
  const fac_name = decodedUserinfo?.facilityname
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const alertContext = useContext(AlertContext);
  const [state, setState] = useState({
    anchorEl: null,
    open: false,
    permissons: null,
    drawerDataList: [],
    pic: "",
  });
  const [roleOpen, setRoleOpen] = React.useState(false);

  const { email } = getUserInfo();
  const {
    navBarArr = [],
    noHome,
    // middleComponent = <></>,
    parent_id,
    hideHumburger,
  } = props;

  // const loggedUserInfo = useSelector(
  //   (state) => state?.authSlice?.loggedUserInfo
  // );

  // const dispatch = useDispatch();

  // const fetchUserInfo = async (email) => {
  //   if (!loggedUserInfo?.data?.orgId) {
  //     if (!email) {
  //       const info = getUserInfo();
  //       email = info.email;
  //     }
  //     const userData = await dispatch(
  //       actions.SEARCH_PRACTIONER({
  //         email,
  //       })
  //     );

  //     const info = {
  //       orgId: userData?.payload?.data?.[0]?.OrgID?.[0],
  //       name: {
  //         given: userData?.payload?.data?.[0]?.name?.[0]?.given,
  //         family: userData?.payload?.data?.[0]?.name?.[0]?.family,
  //         text: userData?.payload?.data?.[0]?.name?.[0]?.text,
  //       },
  //       practionerId: userData?.payload?.data?.[0]?.practitioner?.[0]?.id,
  //       role: userData?.payload?.data?.[0]?.practitioner?.[0]?.PractitionerRole[0]?.code[0]?.coding?.[0]?.display?.toLowerCase(),
  //       org_id: userData?.payload?.data?.[0]?.org_id,
  //     };
  //     await dispatch(actions.LOGGED_USER_INFO(info));
  //     return info;
  //   }
  //   return loggedUserInfo?.data;
  // };

  React.useEffect(() => {
    //let userData = fetchData(name.email);
    //console.log("USERDATA", userData);
    getDrawerData(setState, props);
  }, []);

  const open = Boolean(state.anchorEl);
  const id = open ? "simple-popover" : undefined;
  let { permissons } = state;
  const handleRoleClick = () => {
    setRoleOpen(true);
  };
  const handleRoleClose = () => {
    setRoleOpen(false);
  };
  const getPractitionerId = () => {
    return email;
  };
  const onNextClick = async (
    location,
    role,
    facilitystate,
    organisation,
    enterprise,
  ) => {
    setRoleOpen(false);
    if (email  && role && facilitystate&&location) {
      const payloadlogin = {
        username: email,
        tenantid: __tenantid__,
        roleid: role?.id,
        locationid: location?.id,
        facilityid: facilitystate?.id,
        orgid:organisation?.id,
        enterpriseid:enterprise?.id,
      };
      let token = localStorage.getItem("Token");
      await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: `${token}`,
        },
        url: urls.practitionerLogin,
        data: payloadlogin,
      })
        .then((res) => {
          if (res?.data?.statusCode === 201 && !res.data.error) {
            const status = checkError(res);

            if (status.isError) {
              alertContext.setSnack({
                open: true,
                severity: AlertProps.severity.error,
                msg: status.errMsg,
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.right,
              });
            } else {
              const roleData = res?.data?.response?.role_data;
              const permissionData = res?.data?.response?.permissionData;
              const userInfo = res?.data?.response?.userinfo;
              const token = res?.data?.response?.token;
              const jwtToken = res?.data?.response?.jwttoken;
              if (roleData && permissionData && userInfo && token && jwtToken) {
                localStorage.removeItem("Token");
                localStorage.removeItem("RoleData");
                localStorage.removeItem("PermissionData");
                localStorage.removeItem("UserInfo");
                localStorage.removeItem("JwtToken");
                localStorage.removeItem("RoleName");
                // sessionStorage.removeItem("sessioninfo");
                const decodedUserinfo = jwtDecode(userInfo);
                localStorage.setItem("RoleData", roleData);
                localStorage.setItem("PermissionData", permissionData);
                localStorage.setItem("UserInfo", userInfo);
                localStorage.setItem("Token", token);
                localStorage.setItem("JwtToken", jwtToken);
                localStorage.setItem("RoleName", decodedUserinfo.rolename);
                // isFullScreen(true);
                navigate(AppRoutes.dashboard, {
                  state:{
                   userRoleChange:true
                },})
                // setRoleOpen(false);
              } else {
                alertContext.setSnack({
                  open: true,
                  severity: AlertProps.severity.error,
                  msg: "Access not found",
                  vertical: AlertProps.vertical.top,
                  horizontal: AlertProps.horizontal.right,
                });
                // setRoleOpen(false);
              }
            }
          } else {
            alertContext.setSnack({
              open: true,
              severity: AlertProps.severity.error,
              msg: res.data.message,
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.right,
            });
          }
        })
        .catch((error) => {
          alertContext.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "Please contact to admin",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
          });
        });
    } else {
      const errorMessage = "Please fill all the mandatory fields !";
      alertContext.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: errorMessage,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      setState({
        error: errorMessage,
      });
    }
  };

  return (
    <div id={`${parent_id}_header_parent_div`}>
      <Container
        id={`${parent_id}_header_parent_container`}
        key={"0"}
        name="container"
        fluid={true}
        style={{
          backgroundColor: !props.hideBackground ? "#e8e8e8" : "transparent",
          padding: "0px",
        }}
      >
        <Row
          id={`${parent_id}_header_parent_row`}
          key={"0"}
          alignItems="stretch"
          direction="row"
          justify="flex-start"
          spacing="2"
          style={{
            backgroundColor: "#179e8e",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "8px 20px 14px 20px",
          }}
        >
          <Div
            id={`${parent_id}_header_section_div`}
            key={"0"}
            inLineStyles="[object Object]"
            className="qdm"
            style={{
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {!hideHumburger &&
                (permissons?.write?.length > 0 ||
                  permissons?.read?.length > 0) && (
                  <div
                    style={{
                      marginRight: 18,
                      cursor: "pointer",
                      display: "flex",
                    }}
                  >
                    <Hamburger
                      onClick={() => changeState("open", true, state, setState)}
                    />
                   <Drawer
                      id="sidemenu_parent_sidemenu"
                      open={state?.open}
                      inLineStyles={{
                        padding: "20px 0px",
                        cursor: "auto",
                      }}
                      PaperProps={{
                        style: {
                          width: 270,
                        },
                      }}
                      direction="left"
                      size="lg"
                      color="default"
                      onClose={() => changeState("open", false, state, setState)}
                    >
                      <CustomDrawer
                        list={state.drawerDataList}
                        style={{ width: "300px" }} // Ensure the same width for CustomDrawer
                        onClose={() => changeState("open", false, state, setState)}
                      />
                    </Drawer>

                  </div>
                )}
              <div
                style={{ display: "flex" }}
                id={`${parent_id}_header_logo_image_div`}
              >
                <img
                  id={`${parent_id}_header_logo_image`}
                  key={"0"}
                  variant="rounded"
                  letter="Logo"
                  // src={Logo}
                  src={ForsightLogo}
                  alt="Logo"
                  // style={{
                  //   width: "30%",
                  //   height: "30%",
                  //   borderRadius: 4,
                  // }}
                  style={{
                    //height: 40,
                    width: 120,
                    borderRadius: 2,
                  }}
                />
                
              </div>
              <Divider
              id={`${parent_id}_header_rightside_divider`}
              orientation="vertical"
              style={{ width: "1px", height: "32px" }}
            />
              {/* <Divider
                id={`${parent_id}_header_rightside_divider`}
                key={"0"}
                className=""
                label=""
                borderColor=""
                textColor=""
                orientation="vertical"
                variant="fullWidth"
                style={{ height: "24px", margin: "0px 8px", width: "1.5px", backgroundColor: "white" }}
              ></Divider>  */}
              <div>
              <Text
                id={`${parent_id}_header_label_text`}
                name={fac_name || ""}
                style={{
                  color: "#ffffff",
                  // fontSize: "16px",
                  // fontWeight: "400",
                  // margin: "0px 15px",
                  textTransform: "uppercase"
                }}
                inLineStyles={{
                  fontFamily: "'pc_semibold' !important",
                }}
                className={"pc_semibold"}
              ></Text>
              </div>      
            </div>
            <Div
              id={`${parent_id}_header_sub_div`}
              key={"1"}
              inLineStyles="[object Object]"
              className="qdm"
              style={{
                display: "flex",
                marginLeft: "20px",
              }}
            >
              {navBarArr.map((val, i) => {
                return (
                  <Div
                    id={`${parent_id}_header_label_div`}
                    key={"0"}
                    inLineStyles="[object Object]"
                    className="qdm"
                    style={{
                      display: "",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "",
                    }}
                    onClick={() => this.redirectLink(val)}
                  >
                    <Text
                      id={`${parent_id}_header_label_text`}
                      name={val.label}
                      style={{
                        color: "#ffffff",
                        fontSize: "16px",
                        fontWeight: "normal",
                      }}
                      inLineStyles={{
                        fontFamily: "'pc_semibold' !important",
                      }}
                      className={"pc_semibold"}
                    ></Text>
                    {giveMeSelectedTab(props) === val.id && (
                      <Divider
                        id={`${parent_id}_header_rightside_label`}
                        key={"1"}
                        className=""
                        label=""
                        borderColor=""
                        textColor=""
                        orientation="horizontal"
                        variant="middle"
                        style={{
                          // width: '40px',
                          height: "3px",
                          borderColor: "#ffffff",
                          backgroundColor: "#ffffff",
                          margin: "4px auto",
                        }}
                      ></Divider>
                    )}
                  </Div>
                );
              })}
            </Div>
          </Div>
          {!location?.state?.skip_hospital_searcch_screen && (
            <Div
              id={`${parent_id}_header_middlecomponent_div`}
              key={"1"}
              inLineStyles="[object Object]"
              className="qdm"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                width: "",
              }}
            >
              <Col id={`${parent_id}_header_title_col`} key={"0"}>
                {props?.values && (
                  <Div
                    id={`${parent_id}_header_title_div`}
                    key={"0"}
                    inLineStyles="[object Object]"
                    className="qdm"
                    style={{
                      backgroundColor: "#fff",
                      marginLeft: "",
                      marginRight: "",
                      borderRadius: "36px",
                      marginBottom: "",
                      marginTop: "",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      height: "40px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    <Div
                      id={`${parent_id}_header_title_sub_div`}
                      key={"0"}
                      // onClick={() => props.onHeaderClicked(1)}
                      inLineStyles="[object Object]"
                      className="qdm"
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        marginLeft: "",
                        cursor: "pointer",
                      }}
                    >
                      <Avatar
                        id={`${parent_id}_header_user_image`}
                        key={"0"}
                        variant="rounded"
                        letter={
                          props?.values?.selectedUser?.name || state?.user?.name
                        }
                        src={getImgUrl(props.values?.selectedUser?.fileid)}
                        alt="Image"
                        style={{
                          width: "28px",
                          height: "28px",
                          marginLeft: "",
                          padding: "0px",
                          fontSize: "14px",
                          margin: 0,
                          color: "#101010",
                          backgroundColor: "#E0E0E0",
                        }}
                        className={"pc_medium"}
                      ></Avatar>
                    </Div>

                    <Div
                      id={`${parent_id}_header_map_div`}
                      key={"1"}
                      inLineStyles="[object Object]"
                      className="qdm"
                      style={{
                        width: "",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <Divider
                        id={`${parent_id}_header_rightside_divider`}
                        key={"0"}
                        className=""
                        label=""
                        borderColor=""
                        textColor=""
                        orientation="vertical"
                        variant="fullWidth"
                        style={{ height: "24px", margin: "0px 8px" }}
                      ></Divider>

                      {props?.values?.location?.address && (
                        <>
                          <Div
                            id={`${parent_id}_header_location_div`}
                            key={"1"}
                            // onClick={() => props.onHeaderClicked(2)}
                            inLineStyles="[object Object]"
                            className="qdm"
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              marginLeft: "",
                              cursor: "pointer",
                            }}
                          >
                            <H6
                              id={`${parent_id}_header_location_h6`}
                              key={"0"}
                              name={
                                props?.values?.location?.address?.length > 20
                                  ? props?.values?.location?.address?.slice(
                                    0,
                                    17
                                  ) + "..."
                                  : props?.values?.location?.address
                              }
                              //name={`${props?.values?.location?.address ?? ' '}`}
                              // ${props?.values?.cityName}
                              title={props?.values?.location?.address}
                              style={{
                                color: "#101010",
                                fontSize: "14px",
                                textAlign: "left",
                              }}
                              className={"pc_regular"}
                            ></H6>

                            <Icons
                              id={`${parent_id}_header_map_marker_icons`}
                              key={"1"}
                              fontIcon="map-marker"
                              ariaHidden="true"
                              className=""
                              size="small"
                              style={{ marginLeft: "8px", color: "#101010" }}
                            ></Icons>
                          </Div>

                          <Divider
                            id={`${parent_id}_header_rightside_divider`}
                            key={"2"}
                            className=""
                            label=""
                            borderColor=""
                            textColor=""
                            orientation="vertical"
                            variant="fullWidth"
                            style={{ height: "24px", margin: "0px 8px" }}
                          ></Divider>
                        </>
                      )}

                      <Div
                        id={`${parent_id}_header_filter_div`}
                        key={"3"}
                        // onClick={() => props.onHeaderClicked(3)}
                        inLineStyles="[object Object]"
                        className="qdm"
                        style={{ display: "flex", cursor: "pointer" }}
                      >
                        <Div
                          id={`${parent_id}_header_filter_sub_div`}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                        >
                          {[
                            ...(props?.values?.symptoms || []),
                            ...(props?.values?.specialities || []),
                            ...(props?.values?.doctors || []),
                          ]
                            ?.filter((l, i) => i < 1)
                            ?.map((val, i) => (
                              <span
                                id={`${parent_id}_${i}_header_filter_span`}
                                style={{
                                  borderColor: "#e0e0e0",
                                  borderWidth: "1px",
                                  borderStyle: "solid",
                                  borderRadius: "4px",
                                  color: "#101010",
                                  cursor: "pointer",
                                  fontSize: 14,
                                  marginRight: 8,
                                  padding: "2px 8px",
                                  display: "flex",
                                  alignItems: "center",
                                  lineHeight: 1.4,
                                }}
                                className={"pc_regular"}
                              >
                                {val?.label}{" "}
                                <CloseIcon
                                  id={`${parent_id}_${i}_header_filter_close`}
                                  style={{
                                    marginLeft: 8,
                                    fontSize: 16,
                                    color: "#6f6f6f",
                                  }}
                                />
                              </span>
                            ))}
                          {/* {[
                          ...props?.values?.symptoms,
                          ...props?.values?.specialities,
                          ...props?.values?.doctors,
                        ]?.length > 0 &&
                          [
                            ...props?.values?.symptoms,
                            ...props?.values?.specialities,
                            ...props?.values?.doctors,
                          ]?.length > 1 && (
                            <span
                              id={`${parent_id}_header_filter_more_span`}
                              style={{
                                borderColor: "#e0e0e0",
                                borderWidth: "1px",
                                borderStyle: "solid",
                                borderRadius: "4px",
                                color: "#101010",
                                cursor: "pointer",
                                fontSize: 14,
                                padding: "2px 8px",
                                display: "flex",
                                alignItems: "center",
                                lineHeight: 1.4,
                              }}
                              className={"pc_regular"}
                            >
                              {"+"}
                              {[
                                ...props?.values?.symptoms,
                                ...props?.values?.specialities,
                                ...props?.values?.doctors,
                              ]?.length - 1}
                              {" More"}
                            </span>
                          )} */}
                        </Div>
                      </Div>

                      <Divider
                        id={`${parent_id}_header_divider`}
                        key={"4"}
                        className=""
                        label=""
                        borderColor=""
                        textColor=""
                        orientation="vertical"
                        variant="fullWidth"
                        style={{ height: "24px", margin: "0px 8px" }}
                      ></Divider>

                      <Div
                        id={`${parent_id}_header_hospital_div`}
                        key={"5"}
                        // onClick={() => props.onHeaderClicked(4)}
                        inLineStyles="[object Object]"
                        className="qdm"
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        {/* <Text
                          key={"0"}
                          name={props?.values?.hospital?.label}
                          style={{ color: "#6F6F6F" }}
                        ></Text> */}
                        {props?.values?.hospital?.length === 1 && (
                          <span
                            id={`${parent_id}_header_hospital_span`}
                            style={{
                              borderColor: "#e0e0e0",
                              borderWidth: "1px",
                              borderStyle: "solid",
                              borderRadius: "4px",
                              color: "#101010",
                              cursor: "pointer",
                              fontSize: 14,
                              padding: "2px 8px",
                              display: "flex",
                              alignItems: "center",
                              lineHeight: 1.4,
                            }}
                            className={"pc_regular"}
                          >
                            {props?.values?.hospital?.[0]?.label}
                          </span>
                        )}
                        {props?.values?.hospital?.length > 1 && (
                          <span
                            id={`${parent_id}_header_hospital_more_span`}
                            style={{
                              borderColor: "#e0e0e0",
                              borderWidth: "1px",
                              borderStyle: "solid",
                              borderRadius: "4px",
                              color: "#101010",
                              cursor: "pointer",
                              fontSize: 14,
                              padding: "2px 8px",
                              display: "flex",
                              alignItems: "center",
                              lineHeight: 1.4,
                            }}
                            className={"pc_regular"}
                          >
                            {"+"}
                            {props?.values?.hospital?.length - 1}
                            {" More"}
                          </span>
                        )}
                        <Icons
                          id={`${parent_id}_header_hospital_down_icons`}
                          key={"1"}
                          fontIcon="angle-down"
                          ariaHidden="true"
                          className=""
                          size="small"
                          style={{ marginLeft: "8px" }}
                        ></Icons>
                      </Div>
                    </Div>

                    <Div
                      id={`${parent_id}_header_hospital_search_div`}
                      key={"2"}
                      inLineStyles="[object Object]"
                      className="qdm"
                      style={{
                        cursor: "pointer",
                        backgroundColor: "#EC6A49",
                        borderRadius: "50px",
                        marginLeft: "8px",
                        width: "28px",
                        height: "28px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {/* eslint-disable-next-line */}
                      <img
                        id={`${parent_id}_header_hospital_search_img`}
                        src="https://i.ibb.co/QD4fvNs/icons8-search.png"
                        alt="Image"
                        style={{
                          width: 12,
                          height: 12,
                        }}
                      />
                    </Div>
                  </Div>
                )}
              </Col>
            </Div>
          )}
          <Div
            id={`${parent_id}_header_profile_parent_div`}
            key={"2"}
            inLineStyles="[object Object]"
            className="qdm"
            style={{
              display: email ? "flex" : "none",
              // alignSelf: "flex-start",
              alignItems: "center",
              width: "40%",
              justifyContent: "right",
            }}
          >
            {!noHome && (
              <Div
                id={`${parent_id}_header_home_div`}
                style={{
                  cursor: "pointer",
                  paddingRight: "2%",
                  marginBottom: "0.6%",
                }}
                onClick={() => clickHome(props, navigate)}
              >
                <i
                  id={`${parent_id}_header_home_icon`}
                  className="fa fa-home"
                  aria-hidden="true"
                  style={{
                    fontSize: "16px !important",
                    paddingRight: "7px",
                    color: "white",
                  }}
                />
                <Text
                  id={`${parent_id}_header_home_label_text`}
                  className={"pc_medium"}
                  style={{ color: "white" }}
                >
                  HOME
                </Text>
              </Div>
            )}
            <H6
              id={`${parent_id}_header_version_text`}
              key={"0"}
              name={"version : " + localStorage.getItem("version")}
              style={{ color: "#FFFFFF", fontSize: 12, margin: "3px 0px" }}
              className={"pc_regular"}
            ></H6>
            <Divider
              id={`${parent_id}_header_rightside_divider`}
              orientation="vertical"
              style={{ width: "1px", height: "32px" }}
            />
            <Popover
              id={id}
              open={open}
              anchorEl={state.anchorEl}
              onClose={() => handleProfileClose(state, setState)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Typography
                id={`${parent_id}_header_logout_typography`}
                onClick={() => {
                  Logout();
                  window.location.href = "/";
                }}
                style={{ padding: "10px", cursor: "pointer", fontSize: 14 }}
                className={"pc_medium"}
              >
                Log Out
              </Typography>
            </Popover>

            <Div
              id={`${parent_id}_header_version_div`}
              style={{ padding: "0px", cursor: "pointer" }}
              onClick={(e) => handleProfileClick(e, state, setState)}
            >
              <H6
                id={`${parent_id}_header_name_h6`}
                key={"0"}
                name={email || ""}
                style={{
                  color: "#FFFFFF",
                  lineHeight: 1,
                  fontSize: "14px",
                  margin: "0px",
                  marginBottom: 6,
                }}
                className={"pc_regular"}
              ></H6>
              {/* <Text key={'0'} name='Receptionist Apollo, chennai' style={{ color: '#FFFFFF', fontSize: "12px" }}></Text> */}

              <H6
                id={`${parent_id}_header_version_text`}
                key={"0"}
                name={
                  "Role : " +
                  CapitalizeString(
                    props?.loggedUserInfo?.data?.role || roleName || ""
                  )
                }
                style={{
                  color: "#FFFFFF",
                  fontSize: 12,
                  margin: 0,
                  marginRight: "80px",
                }}
                className={"pc_regular"}
              ></H6>
            </Div>
            <SwapHorizontalCircleIcon
              onClick={handleRoleClick}
              style={{ color: "#fff", fontSize: "2em" }}
            />
            <Avatar
              id={`${parent_id}_header_letters_name_avatar`}
              key={"1"}
              variant="rounded"
              letter={email ?? props?.loggedUserInfo?.data?.completeName ?? "F"}
              // src={props?.loggedUserInfo?.data?.photo}
              src={state?.pic ?? ""}
              alt={props?.loggedUserInfo?.data?.completeName}
              style={{
                width: "40px",
                height: "40px",
                padding: "",
                // marginLeft: "16px",
                // marginRight: "10px",
                backgroundColor: "#ffffff",
                fontSize: "25px",
                fontWeight: "bold",
                fontFamily: "pc_medium",

                color: "#17a99e",
                margin: "0px 10px 0px 16px",
              }}
            ></Avatar>
            <Icons
              id={`${parent_id}_header_down_icon`}
              onClick={(e) => handleProfileClick(e, state, setState)}
              key={"2"}
              fontIcon="angle-down"
              ariaHidden="true"
              className=""
              size={18}
              style={{ color: "#ffffff", cursor: "pointer" }}
            ></Icons>
          </Div>
        </Row>
        <Dialog
          open={roleOpen}
          // onClose={handleRoleClose}
          // classes={{ paper: classes.RoledialogPaper }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ padding: "0px" }}
        >
          <DialogTitle id="alert-dialog-title" style={{ paddingBottom: "0px" }}>
            Select Location and Role
            <IconButton
              aria-label="close"
              onClick={handleRoleClose}
              style={{ position: "absolute", right: 0, top: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent
            style={{
              padding: "0px",
              paddingBottom: "10px",
              paddingRight: "20px",
            }}
          >
            <LocationRoles
              practitonerId={getPractitionerId()}
              onNextClick={onNextClick}
              sessioninfoValue={JSON.parse(localStorage.getItem("sessioninfo"))}
            />
          </DialogContent>
        </Dialog>
      </Container>
    </div>
  );
};

export default AppHeader;
